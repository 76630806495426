<template>
    <div>
        <div class="border border-color-vio block rounded shadow p-3 mb-5 bg-white rounded text-start"
            v-for="Contact_Info in Contacts"
            v-bind:key="Contact_Info">
            <h4 class=" text-center content-blocks-title"><strong>{{Contact_Info.Block_Title}}</strong></h4>
            <hr />
            <BlckAdvisorDetail
                :Gender="Contact_Info.Gender"
                :Contact_Name="Contact_Info.Contact_Name"
                :Availability_Slot="Contact_Info.Availability_Slot"
                :Phones_Numbers="Contact_Info.Phones_Numbers" />
        </div>
    </div>
</template>

<script>
import getPhonesByUser from '../../mixins/getPhonesByUser';
import BlckAdvisorDetail from '../Mon-Conseiller/BlckAdvisorDetail.vue';
import getAdvisorRequest from '../../services/graphql/requests/getAdvisorRequest';

/**
 * This component displays a card with advisor
 */
export default {
    components: {
        BlckAdvisorDetail
    },
    mounted() {
        this.Contacts = [this.getCanContact()];
        // rc
        this.getData();
    },
    data() {
        return {
            Contacts : []
        };
    },
  methods: {
        /**
         *
         * @param {User} user
         * @param {array} phones
         * @param {bool} isAc
         * @returns {object}
         */
      createContact(user, phones, isAc = false) {
          return {
                Block_Title: isAc ? "Mon attaché commercial" : "MON CONSEILLER",
                Contact_Name: user.firstname + ' ' + user.lastname,
                Availability_Slot : "Joignable du lundi au vendredi de 8h à 19h",
                Phones_Numbers: phones,
                Gender: user.title == 'Monsieur' ? 'male' : 'female'
            };
        },
        getData() {
            getAdvisorRequest().then(async (data) => {
                const advisor = data.client.rc;
                if (advisor && advisor.id !== "1676") {
                    // get phones for advisor
                    const phones = this.getPhonesByUser(advisor);
                    if (phones.length) {
                        this.setContactsWithAdvisorAndPhones(advisor, phones);
                    }
                    // get other contacts
                    const contacts = await this.getSuperiorsContactByUser(advisor);
                    this.pushContacts(contacts);
                }
            }).catch((reason) => {
                console.error(reason);
            });
        },
        /**
         *
         * @param {User[]} contacts
         */
        pushContacts(contacts) {
            if (contacts.length) {
                for(let i = 0; i < contacts.length; i++) {
                    const phones = this.getPhonesByUser(contacts[i]);
                    const contact = this.createContact(contacts[i], phones, true);
                    this.Contacts.push(contact);
                }
            }
        },
        /**
         * @param {User} advisor
         * @param {array} phones
         */
        setContactsWithAdvisorAndPhones(advisor, phones) {
            this.Contacts = [this.createContact(advisor, phones)];
        }
    },
    mixins: [getPhonesByUser]
}
</script>
