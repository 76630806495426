<template>
  <div class="row support-div gx-5">
    <div class="col-3 gx-1">
      <img v-if="Gender === 'male'" src="@/assets/images/male.svg" class="support-icon"/>
      <img v-else src="@/assets/images/female.svg" class="support-icon"/>
    </div>
    <div class="col-8 text-start">
      <h4 class="content-blocks-title"><b>{{ Contact_Name }}</b></h4>
      <p class="support-contact-infos-content">{{ Availability_Slot }}</p>
      <div
          v-for="Phone_Number in Phones_Numbers.filter(phone => phone)"
          v-bind:key="Phone_Number"
          class="text-left">
        <button type="button"
                class="button-warning-validate to-validate-with-frost" @click="call(Phone_Number)">
          <img src="@/assets/images/phone.svg" class="me-2"/>{{ Phone_Number }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * This component displays detail about an advisor
 */
export default {
  props: {
    Contact_Name: String,
    Availability_Slot: String,
    Phones_Numbers: Array,
    Gender: String
  },
  methods: {
    call(phoneNumber) {
      window.location.href='tel:'+phoneNumber
    }
  }
}
</script>
